<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12">
        <h1>Create a new quotation</h1>
        <app-look-up-customer
          v-if="quotation.customer === 0"
          @populateCustomer="populateCustomer" />
        <div class="mt-3">
          <router-link
            v-if="quotation.customer === 0"
            to="/customers/create">
            <v-btn small>Add New Customer</v-btn>
          </router-link>
        </div>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <h4>Customer</h4>
            <p v-if="quotation.customer.name">{{ quotation.customer.name }}</p>
            <h4>Customer address</h4>
            <span v-if="quotation.customer.address_1">{{ quotation.customer.address_1 }}</span>
            <span v-if="quotation.customer.address_2"><br />{{ quotation.customer.address_2 }}</span>
            <span v-if="quotation.customer.address_3"><br />{{ quotation.customer.address_3 }}</span>
            <span v-if="quotation.customer.address_4"><br />{{ quotation.customer.address_4 }}</span>
            <span v-if="quotation.customer.address_5"><br />{{ quotation.customer.address_5 }}</span>
            <span v-if="quotation.customer.postcode"><br />{{ quotation.customer.postcode }}</span>
            <h4 class="pt-4 pb-0">Customer telephone</h4>
            <span v-if="quotation.customer.tel">{{ quotation.customer.tel }}</span>
            <h4 class="pt-4 pb-0" v-if="quotation.customer.notes">Notes</h4>
            <p v-if="quotation.customer.notes">{{ quotation.customer.notes}}</p>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4 pt-4">
            <v-btn
              small
              @click="quotation.customer = 0">
              Change Customer
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              v-model="quotation.customer_ref"
              label="Customer reference"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              :items="departments"
              v-model="quotation.department_id"
              label="Department"
              suffix="*"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              :items="contacts"
              v-model="quotation.contact_id"
              item-text="name"
              item-value="id"
              label="Contact"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn
              @click="addNewContact = true"
              small>Add New Contact</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.first_name" label="First Name"></v-text-field>
          </v-col>
          <v-col class="col-5 col-sm-3 col-md-2">
            <v-text-field v-model="newContact.last_name" label="Last Name"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.email" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="newContact.tel" label="Tel"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addNewContact === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn small @click="saveNewContact">Save contact</v-btn>
            &nbsp;
            <v-btn small @click="cancelNewContact">Cancel</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field label="Start Address" v-model="quotation.startAddress" />
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addressLocked === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-combobox
              @change="populatePostCode"
              :items="sites"
              v-model="quotation.site"
              item-text="address"
              item-value="address"
              label="Project Address"></v-combobox>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addressLocked === false">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              hint="If not know enter TBC"
              persistent-hint
              @blur="lockAddress"
              v-model="quotation.site_post_code"
              label="Project Postcode"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addressLocked === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              disabled
              v-model="quotation.site"
              label="Project Address"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addressLocked === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              disabled
              v-model="quotation.site_post_code"
              label="Project Postcode"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0 && addressLocked === true">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn small @click="unlockAddress">Change Address</v-btn>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <app-directions
              v-if="quotation.site_post_code.length > 3"
              :origin="quotation.startAddress"
              :address="quotation.site"
              :postcode="quotation.site_post_code"
              @routeFound="populateDistances" />
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="quotation.distance" label="Distance"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="quotation.drive_time" label="Drive Time (Minutes)"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <label>Description of Works</label>
            <vue-editor
              id="details"
              :editor-toolbar="customToolbar"
              v-model="quotation.details" />
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <label>Notes</label>
            <vue-editor
              id="notes"
              :editor-toolbar="customToolbar"
              v-model="quotation.notes" />
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.quotation_valid_for"
              :items="validities"
              label="Quotation Valid For">
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-checkbox
              hint="Leave unticked if not required"
              persistent-hint
              v-model="quotation.sectorSeven" 
              label="Sector Seven"></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <template>
              <v-date-picker
                v-if="!date"
                v-model="date"
                show-adjacent-months
                no-title
                width="100%"
              ></v-date-picker>
            </template>
            <v-text-field
              v-if="date"
              :value="prettyDate"
              append-icon="mdi-close"
              @click:append="resetDate"
              label="Quotation date">
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field v-model="quotation.drawing_ref" label="Drawing Ref"></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-autocomplete
              v-model="quotation.surface_type_id"
              :items="surfaceTypes"
              item-text="surface_type"
              item-value="id"
              label="Surface type that materials to be applied to"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              v-model="quotation.days_for_job"
              label="Visits required"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.notice_for_job"
              :items="['7 Days', '14 Days', '28 Days']"
              label="Notice required for starting work"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-text-field
              type="number"
              v-model="quotation.extra_unsociable_hours"
              label="Extra % for unsociable hours"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.dom_vat_0"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Riggots supply to be within the CIS scheme?"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.dom_vat_1"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Is the customer VAT registered?"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-select
              v-model="quotation.dom_vat_2"
              :items="[{ value: 0, text: 'No'}, { value: 1, text: 'Yes'}]"
              label="Is the customer a contractor in the chain?"
            ></v-select>
          </v-col>
        </v-row>
        <v-row v-if="quotation.customer !== 0">
          <v-col class="col-10 col-sm-6 col-md-4">
            <v-btn @click="saveQuotation">
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showMessage"
      max-width="290"
    >
      <v-card>
        <v-card-text class="pt-4">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="orange darken-2"
            text
            @click="showMessage = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Directions from '@/components/googlemaps/Directions.vue';
import LookUpCustomer from '@/components/Quotations/LookupCustomer.vue';
import axios from '../../axios';
import { VueEditor } from "vue2-editor";
import { format, parseISO } from 'date-fns';

export default {
  name: 'CreateQuotation',
  components: {
    VueEditor,
    appDirections: Directions,
    appLookUpCustomer: LookUpCustomer,
  },
  data() {
    return {
      customer: {},
      quotation: {
        customer: 0,
        date: null,
        drawing_ref: '',  
        site: '',
        details: '',
        sectorSeven: 0,
        department_id: 0,
        distance: '',
        drive_time: '',
        site_post_code: '',
        startAddress: 'Lodge Lane Industrial Estate, Lodge Ln, Tuxford, Newark NG22 0NL, United Kingdom',
      },
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      prettyDate: format(parseISO((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)), 'EEEE, MMMM do yyyy'),
      departments: [
        { value: 1, text: 'Paint' },
        { value: 2, text: 'Thermo' },
        { value: 3, text: 'Both' },
        { value: 4, text: 'Sale of Goods' },
      ],
      validities: [
        '10 days',
        '30 days',
        '60 days',
        '90 days',
        '180 days',
      ],
      contacts: [],
      sites: [],
      surfaceTypes: [],
      message: '',
      showMessage: false,
      addressLocked: false,
      addNewContact: false,
      newContact: {
        first_name: '',
        last_name: '',
        email: '',
        tel: '',
      },
      customToolbar: [
        ["bold", "italic", "underline"],
      ],
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  watch: {
    date() {
      this.prettyDate = format(parseISO(this.date), 'EEEE, MMMM do yyyy');
      this.quotation.date = this.date;
    },
  },
  methods: {
    cancelNewContact() {
      this.addNewContact = false;
      this.newContact = null;
    },
    saveNewContact() {
      const postData = {};
      postData.customerId = this.quotation.customer.id;
      postData.contact = this.newContact;
      axios.post(`/contacts/addFromQuote.json?token=${this.token}`, postData)
        .then((response) => {
          this.quotation.contact_id = response.data.contact.id;
          this.quotation.siteContact = response.data.contact.full_name;
          this.quotation.siteTel = response.data.contact.tel;
          this.quotation.siteEmail = response.data.contact.email;
          const cont = {};
          cont.id = response.data.contact.id;
          cont.name = response.data.contact.full_name;
          cont.email = response.data.contact.email;
          cont.tel = response.data.contact.tel;
          this.contacts.push(cont)
          this.addNewContact = false;
          this.newContact = {};
        })
        .catch((error) => {
          console.log(error);
        });
    },
    populateCustomer(customer) {
      axios.get(`/customers/get/${customer.id}.json?token=${this.token}`)
        .then((response) => {
          this.foundCustomers = [];
          this.customer = '';
          this.quotation.customer = response.data.customer;
          this.contacts = response.data.contacts;
          this.sites = response.data.sites;
          this.getJobRef();
        });
    },
    populatePostCode() {
      if (this.quotation.site.address) {
        const siteAddress = this.quotation.site.address;
        let site = '';
        for (let i = 0; this.sites.length > i; i += 1) {
          if (this.sites[i].address === siteAddress) {
            site = this.sites[i];
          }
        }
        this.quotation.site = site.address;
        this.quotation.site_post_code = site.postcode;
      }
    },
    unlockAddress() {
      this.quotation.site_post_code = '';
      this.quotation.site = '';
      this.addressLocked = false;
    },
    lockAddress() {
      this.addressLocked = true;
    },
    populateDistances(value) {
      this.quotation.distance = value.distance;
      this.quotation.drive_time = value.duration;
    },
    getSurfaceTypes() {
      axios.get(`/surfaceTypes/getAll.json?token=${this.token}`)
        .then((response) => {
          this.surfaceTypes = response.data;
        });
    },
    saveQuotation() {
      if (this.quotation.department_id === 0) {
        this.message = 'Please select a department';
        this.showMessage = true;
      } else {
        const postData = {};
        postData.quotation = this.quotation;
        postData.date = this.date;
        axios.post(`/quotations/create.json?token=${this.token}&user=${this.userid}`, postData)
          .then((response) => {
            const quotationId = response.data.id;
            this.$router.push(`/quotations/add-lines/${quotationId}`);
          });
      }
    },
    checkCustomer() {
      if (this.$route.params.customer) {
        const customerId = this.$route.params.customer;
        axios.get(`/customers/getSingleForQuote/${customerId}.json?token=${this.token}`)
        .then((response) => {
          this.quotation.customer = response.data.customer;
          this.contacts = response.data.contacts;
          this.sites = response.data.sites;
        });
      }
    },
    getJobRef() {
      axios.get(`/quotations/getJobRef.json?token=${this.token}`)
      .then((response) => {
        this.quotation.jobRef = response.data;
      });
    },
    resetDate() {
      this.prettyDate = null;
      this.date = null;
      this.quotation.date = null;
    },
  },
  mounted() {
    this.getSurfaceTypes();
    this.checkCustomer();
  }
}
</script>
